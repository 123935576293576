import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const textFn = /* @__PURE__ */ createRecipe('text', {
  "oll": true,
  "allowNaturalMb": false,
  "variant": "body1",
  "isLink": false
}, [
  {
    "allowNaturalMb": true,
    "variant": "h1",
    "css": {
      "marginBottom": "$h1"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "h2",
    "css": {
      "marginBottom": "$h2"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "h3",
    "css": {
      "marginBottom": "$h3"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "h4",
    "css": {
      "marginBottom": "$h4"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "h5",
    "css": {
      "marginBottom": "$h5"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "h6",
    "css": {
      "marginBottom": "$h6"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "subtitle1",
    "css": {
      "marginBottom": "$subtitle1"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "subtitle2",
    "css": {
      "marginBottom": "$subtitle2"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "body1",
    "css": {
      "marginBottom": "$body1"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "body2",
    "css": {
      "marginBottom": "$body2"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "button",
    "css": {
      "marginBottom": "$button"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "caption",
    "css": {
      "marginBottom": "$caption"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "overline",
    "css": {
      "marginBottom": "$overline"
    }
  },
  {
    "allowNaturalMb": true,
    "variant": "blockquote",
    "css": {
      "marginBottom": "$blockquote"
    }
  }
])

const textVariantMap = {
  "oll": [
    "true",
    "false"
  ],
  "allowNaturalMb": [
    "true",
    "false"
  ],
  "isLink": [
    "true"
  ],
  "variant": [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "caption",
    "overline",
    "blockquote"
  ]
}

const textVariantKeys = Object.keys(textVariantMap)

export const text = /* @__PURE__ */ Object.assign(memo(textFn.recipeFn), {
  __recipe__: true,
  __name__: 'text',
  __getCompoundVariantCss__: textFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: textVariantKeys,
  variantMap: textVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, textVariantKeys)
  },
  getVariantProps: textFn.getVariantProps,
})